import styled from 'styled-components';

export const Quote = styled.div`
/* font-family: 'playfair display'; */
font-family: 'Amatic SC', cursive;
font-size: 42px;
font-style: normal;
font-weight: 900;
letter-spacing: normal;
text-transform: none;
color: whitesmoke;
padding: 30px;
`;