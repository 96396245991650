import React from "react";

class NotFoundPage extends React.Component {
    constructor() {
        super()
        this.state = {}
    }
    render() {
        return (
            <div>
                <h1>404 Not Found!</h1>
            </div>
        );
    }
};

export default NotFoundPage;